export default function LoginSvgIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="88.363px"
            height="122.879px"
            viewBox="0 0 88.363 122.879"
            xmlSpace="preserve"
        >
            <path d="M42.078 64.589H3.118c-1.722 0-3.118-1.41-3.118-3.15s1.396-3.149 3.118-3.149h38.958L29.405 43.778a3.16 3.16 0 01.268-4.429 3.085 3.085 0 014.385.271l17.238 19.742a3.159 3.159 0 01-.02 4.179L34.057 83.26a3.085 3.085 0 01-4.385.271 3.162 3.162 0 01-.268-4.43l12.674-14.512zm5.356 58.197a3.295 3.295 0 11-1.551-6.406c4.258-1.032 7.932-1.761 11.303-2.428 15.99-3.168 24.566-4.866 24.566-27.147V34.258c0-20.817-9.068-22.512-24.086-25.318-3.596-.672-7.498-1.401-11.783-2.441A3.295 3.295 0 0147.434.093c4.02.976 7.885 1.698 11.447 2.363 18.383 3.436 29.482 5.51 29.482 31.801v52.548c0 27.705-10.443 29.773-29.912 33.63-3.465.686-7.236 1.434-11.017 2.351z" />
        </svg>

    )
}